import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

const BookingItemsTable = (props) => {
    const {items} = props;

    const columns = [
        {
            dataField: 'qty',
            text: 'Qty',
            headerStyle: {width: '60px'},
            formatter: (cell, row) => {
                console.log(row)
                return cell
            }
        },
        {
            dataField: 'name',
            text: 'Service Name',
            formatter: (cell, row) => {
                return (<span className={'bookingSummary'}>{cell}</span>)
            }
        }];

    return (
        <BootstrapTable keyField='id' data={items} columns={columns} noDataIndication={'No Items to Display'} />
    )
}

export default BookingItemsTable