import React from 'react';
import {useRecoilValue} from "recoil";
import ViewBookingForm from "../components/ViewBooking/ViewBookingForm";
import {ViewedBooking} from "../store/store";
import ViewBookingDetail from "../components/ViewBooking/ViewBookingDetail";

const ViewBooking = () => {

    const booking = useRecoilValue(ViewedBooking)

    if (!booking) {
        return <ViewBookingForm />
    }
    return (
        <ViewBookingDetail />
    )
}

export default ViewBooking