import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import {Col, Row} from "reactstrap";

const Covid19 = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Covid-19 Information</title>
                <meta name="description" content={'Covid-19'}/>
            </Helmet>
            <Row>
                <Col>
                    <h1>Covid-19 Information</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>In line with Government guidelines regarding the outbreak of Coronavirus (COVID-19) and the
                    World Health Organisation (WHO) declaring this a public health emergency of international concern,
                    we are asking that all of our external Stakeholders including but not limited to; Sub-Contractors;
                        Suppliers and Customers, take appropriate actions to help prevent the spread of COVID-19.</p>

                    <p>Here we advise Cool Hire’s position on the current COVID 19 Pandemic and provide system usage / guidance to our customers.
                    The safety of our staff and customers are of paramount concern to us and we aim to deliver services as safely as possible, particularly whilst operating on customer sites.
                    We are equally aware of the need to prevent our employees from being a cause of
                    the virus spreading and ensure that all our staff are fully aware of the need to operate in a
                        responsible, safe manner when on client sites.</p>

                   <p>The below control measures have been put in place, these are reviewed and updated in accordance with Government advice:
                    All staff have been made aware of the need to carry out basic hygiene measures including:</p>
                    <ul>
                        <li>Washing hands often with soap and water for a minimum of 20 seconds or using an alcohol-based hand sanitiser (60% alcohol at least) if soap and water are not available.</li>
                        <li>Practising social distancing, by maintaining a distance of 2 meters from other people where
                            this is possible, or 1 meter plus with additional risk mitigation.</li>
                        <li>Avoiding unnecessary contact with people, including handshaking.</li>
                        <li>Our staff will self-isolate if displaying coronavirus symptoms.</li>
                        <li>Additional cleaning, including deep cleaning where necessary, is being carried out at all our
                            Offices and Trailers.</li>
                        <li>All field-based employees have been issued with Personal Protective Equipment, including
                            disposable gloves, goggles, disposable overhauls, and antiseptic wipes.</li>
                    </ul>
                    <p>Our assessment will consider any local rules/risks that are in force regarding the need for additional hygiene measures.
                        Deliveries and Collections:</p>
                    <p>Prior to visiting a site, contact will be made with customers to check whether they are self-isolating
                        due to either being in contact with a confirmed case or have themselves tested positive.</p>
                    <p>If advised THEY ARE NOT self-isolating, we will continue to conduct the works with the current
                        controls - ensuring good personal hygiene practices (washing hands with soap and water or antibacterial/alcohol hand sanitizer) prior to and after the visit.</p>
                    <p>All works will be completed in accordance with Government guidelines on COVID-19.</p>
                    <p>If you have any questions, please do not hesitate to contact a member of the Cool Hire team.</p>
                </Col>
            </Row>
        </Fragment>
    )
}

export default Covid19