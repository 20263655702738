import React from 'react';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";

const MakePaymentBtn = (props) => {
    const {token, bookingId, status} = props;

    const noPaymentStatuses = ['PAID', 'WAIT', 'STOP', 'VOID', 'PRE'];

    if (noPaymentStatuses.includes(status)) {
        return (
            <Button color={'primary'} disabled>
                <FontAwesomeIcon icon={faFilePdf} fixedWidth /> View Invoice PDF
            </Button>
        );
    }

    return (
        <Button color={'primary'} href={`https://coolhire.checkfront.com/reserve/booking/${bookingId}?token=${token}&view=pdf`} target={'_blank'}  rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFilePdf} fixedWidth /> View Invoice PDF
        </Button>
    )
}

export default MakePaymentBtn