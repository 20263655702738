import React, {useEffect, useState} from 'react';
import {useRecoilValue} from "recoil";
import {ServiceState} from "../store/store";
import IsLoading from "../components/Global/IsLoading";
import ServiceNotFound from "../components/Errors/ServiceNotFound";
import ServiceView from "../components/ServicePage/ServiceView";

const ServicePage = (props) => {
    const [isLoading, setLoading] = useState(true);
    const services = useRecoilValue(ServiceState);
    const [service, setServices] = useState()

    useEffect(() =>{
        setLoading(true)
        services.map((item) => {
            if (item.id === props.match.params.webName) {
                setServices(item);
            }
            return null;
        })
        setLoading(false)
    }, [props.match.params.webName, services]);

    if (isLoading) {
        return <IsLoading />
    }
    if (service === undefined) {
        return <ServiceNotFound />
    }
    return (
        <ServiceView service={service} />
    )
}

export default ServicePage