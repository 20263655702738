import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {ServiceState} from "../../store/store";

const MenuItems = () => {
    const services = useRecoilValue(ServiceState);

    return (
        <Fragment>
            <li><Link to={'/'}>Home</Link></li>
            <li className="dropdown"><Link to={'#'}>Fridge &amp; Freezer Trailers</Link>
                <div className="sub-menu-wrap">
                    <ul>
                        {
                            services.map((service) => {
                                if (service.serviceType === 'trailer') {
                                    if (service.development && process.env.NODE_ENV === 'production') {
                                        return null;
                                    }
                                    return <li key={service.id}><Link to={`/services/${service.id}`}>{service.friendlyName}</Link></li>
                                }
                                return null
                            })
                        }
                    </ul>
                </div>
            </li>
            {
                /*
            <li className="dropdown"><Link to={'#'}>Cooling & Heating</Link>
                <div className="sub-menu-wrap">
                    <ul>
                        {
                            services.map((service) => {
                                if (service.serviceType === 'portables') {
                                    if (service.development && process.env.NODE_ENV === 'production') {
                                        return null;
                                    }
                                    return <li key={service.id}><Link to={`/services/${service.id}`}>{service.friendlyName}</Link></li>
                                }
                                return null
                            })
                        }
                    </ul>
                </div>
            </li>
              */
            }
            <li><Link to={'/gallery'}>Gallery</Link></li>
            <li><Link to={"/contact-us"}>Contact Us</Link></li>
        </Fragment>
    )
};

export default MenuItems