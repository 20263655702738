import React, {Fragment} from 'react';

const ContactDetails = () => {
    return (
        <Fragment>
            <h2 className="section-title">Contact Details</h2>
            <div className="content-element4">
                <ul className="contact-info v-type">
                    <li className="info-item">
                        <i className="licon-clock3"/>
                        <div className="item-info">
                            <span>Open 9am-4pm: <br/> Monday - Friday</span>
                        </div>
                    </li>
                    <li className="info-item">
                        <i className="licon-map-marker"/>
                        <div className="item-info">
                            <span>Wintles Hill, Westbury on Severn, <br/> Gloucestershire, GL14 1QB.</span>
                        </div>
                    </li>
                    <li className="info-item">
                        <i className="licon-telephone2"/>
                        <div className="item-info">
                            <span>Phone:</span>
                            <a href="tel:01452270340">01452 270340</a>
                        </div>
                    </li>
                    <li className="info-item">
                        <i className="licon-at-sign"/>
                        <div className="item-info">
                            <span>E-mail:</span>
                            <a href="mailto:hello@coolhiretrailers.co.uk">hello@coolhiretrailers.co.uk</a>
                        </div>
                    </li>
                </ul>
            </div>
        </Fragment>
    )
};

export default ContactDetails