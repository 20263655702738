import React from 'react';
import {Switch, Route} from "react-router-dom";
import HomePage from "../pages/HomePage";
import Error404 from "../pages/Errors/Error404";
import ContactPage from "../pages/ContactPage";
import GalleryPage from "../pages/GalleryPage";
import ServicePage from "../pages/ServicePage";
import Terms from "../pages/Info/Terms";
import Privacy from "../pages/Info/Privacy";
import EnquiryPage from "../pages/EnquiryPage";
import Covid19 from "../pages/Info/Covid19";
import PriceMatch from "../pages/Info/PriceMatch";
import ViewBooking from "../pages/ViewBooking";
import NewBooking from "../pages/NewBooking";

const MainRoutes = (props) => {
    return (
        <Switch>
            <Route exact path={'/'}> <HomePage homeData={props.homeData} homeCarousel={props.homeCarousel}/> </Route>
            <Route exact path={'/services/:webName'} component={ServicePage}/>
            <Route exact path={'/gallery'}><GalleryPage galleryData={props.galleryData}/></Route>
            <Route exact path={'/contact-us'}><ContactPage/></Route>
            <Route exact path={'/info/terms'}><Terms/></Route>
            <Route exact path={'/info/privacy'}><Privacy/></Route>
            <Route exact path={'/info/covid-19'}><Covid19/></Route>
            <Route exact path={'/info/price-match'}><PriceMatch/></Route>
            <Route exact path={'/contact-us'}><ContactPage/></Route>
            <Route exact path={'/enquiry'}><EnquiryPage/></Route>

            <Route exact path={'/booking'}><NewBooking/></Route>
            <Route exact path={'/booking/view'}><ViewBooking/></Route>

            <Route exact path={'/index.php'}> <HomePage homeData={props.homeData} homeCarousel={props.homeCarousel}/>
            </Route>
            <Route render={(props => <Error404 {...props} />)}/>
        </Switch>
    )
};

export default MainRoutes