import Axios from 'axios';

async function SubmitContactForm(data) {
    const URL = `${process.env.REACT_APP_AWS_API_URL}/public/contactsend`;
    const config = {
        headers: {
            'X-API-Key': process.env.REACT_APP_AWS_API_KEY
        }
    }
    return await Axios.post(URL, data, config)

        .catch((error) => {
            console.error(error.status);
            throw new Error(error)
        })
}

export default SubmitContactForm;