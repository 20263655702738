import React from 'react';
import {Link} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {ServiceState} from "../../store/store";
import {version} from '../../../package.json';

const MainFooter = () => {
    const services = useRecoilValue(ServiceState);

    return (
        <footer id="footer" className="footer style-2">
            <div className="main-footer">
                <div className="container extra-width-2">
                    <div className="widget-holder flex-row flex-justify">
                        <div className="widget">
                            <h5 className="widget-title">Contact Us</h5>
                            <p>Cool Hire Ltd<br/>Wintles Hill, Westbury on Severn<br/>Gloucestershire<br/>GL14 1QB</p>
                            <p>Company Number: 11595873</p>
                            <ul>
                                <li>Telephone: <a href="tel:01452270340">01452 270 340</a></li>
                                <li>Emergency: <a href="tel:07482838363">07482 838 363</a></li>
                                <li>Email: <a href="mailto:hello@coolhire.co">hello@coolhire.co</a></li>
                            </ul>
                        </div>
                        <div className="widget">
                            <h5 className="widget-title">Fridge & Freezer Trailers</h5>
                            <div className="">
                                <ul>
                                    {
                                        services.map((service) => {
                                            if (service.serviceType === 'trailer') {
                                                if (service.development && process.env.NODE_ENV === 'production') {
                                                    return null;
                                                }
                                                return <li key={service.id}><Link
                                                    to={`/services/${service.id}`}>{service.friendlyName}</Link></li>
                                            }
                                            return null
                                        })
                                    }
                                </ul>
                            </div>
                        </div>

                        <div className="widget">
                            <h5 className="widget-title">Cooling & Heating</h5>
                            <div className="">
                                <ul>
                                    {
                                        services.map((service) => {
                                            if (service.serviceType === 'portables') {
                                                if (service.development && process.env.NODE_ENV === 'production') {
                                                    return null;
                                                }
                                                return <li key={service.id}><Link
                                                    to={`/services/${service.id}`}>{service.friendlyName}</Link></li>
                                            }
                                            return null
                                        })
                                    }
                                </ul>
                            </div>
                        </div>

                        <div className="widget">
                            <h5 className="widget-title">Key Information</h5>
                            <div className="">
                                <ul>
                                    <li><Link to={`/info/price-match`}>Price Match Guarantee</Link></li>
                                    <li><Link to={`/info/covid-19`}>COVID-19 Policy</Link></li>
                                    <li><Link to={`/info/terms`}>Terms & Conditions</Link></li>
                                    <li><Link to={`/info/privacy`}>Privacy Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="copyright flex-row">
                        <p>Copyright Cool Hire Ltd &copy; {new Date().getFullYear()}</p>
                    </div>
                    <div style={{position: "absolute", bottom: "1em", right: "1em", color:'#babcbe', fontSize: "9px"}}>
                        Website by <a href={'https://www.dankershaw.com'} target={'_blank'} rel="noopener noreferrer">Dan K</a> ({version})
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default MainFooter