import React, {Fragment} from 'react';
import {Row, Col} from 'reactstrap';
import {Helmet} from "react-helmet";

const Privacy = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Terms and Conditions</title>
                <meta name="description" content={'Terms and conditions'}/>
            </Helmet>

            <Row>
                <Col>
                    <h1>Terms & Conditions</h1>
                </Col>
            </Row>

            <Row>
                <Col>
                    <p>To view the latest copy of our terms and conditions please download them from this link: <a href={'/pdf/terms.pdf'}>Terms & Conditions</a>.</p>
                </Col>
            </Row>
        </Fragment>
    )
}

export default Privacy;

