import Axios from "axios";

async function SubmitEnquiryRequest(data) {
    const URL = `${process.env.REACT_APP_AWS_API_URL}/public/enquirysend`;
    const config = {
        headers: {
            'X-API-Key': process.env.REACT_APP_AWS_API_KEY
        }
    }
    const response = await Axios.post(URL, data, config)
        .catch((error) => {
            console.error(error.status);
            throw new Error(error)
        })

    if (!response.data.success) {
        throw new Error(response.data.message)
    }
    return response
}

export default SubmitEnquiryRequest