import React from 'react';
import {Badge} from 'reactstrap';

const PrettyItemStatus = (props) => {
    const {status} = props;

    switch (status){
        case 'PEND':
            return (<Badge color={"info"}>Pending</Badge>);

        case 'HOLD':
            return (<Badge color={"info"}>Reserved (Held)</Badge>);

        case 'WAIT':
            return (<Badge color={"info"}>Waiting (Not Confirmed)</Badge>);

        case 'STOP':
            return (<Badge color={"danger"}>Cancelled</Badge>);

        case 'VOID':
            return (<Badge color={"danger"}>Cancelled (Void)</Badge>);

        case 'PRE':
            return (<Badge color={"primary"}>Pre-Booking</Badge>);

        case 'PART':
            return (<Badge color={"warning"}>Deposit Paid</Badge>);

        case 'PAID':
            return (<Badge color={'success'}>Paid</Badge>);

        default:
            return (status)
    }
}

export default PrettyItemStatus