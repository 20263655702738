import React, {Fragment} from 'react';
import {Row, Col} from 'reactstrap';
import HomeCarousel from "../components/HomePage/HomeCarousel";
import ItemBox from "../components/HomePage/ItemBox";
import {Helmet} from "react-helmet";
import {useRecoilValue} from "recoil";
import {PagesState} from "../store/store";
import ReactHtmlParser from 'react-html-parser';

const HomePage = () => {
    const pagesState = useRecoilValue(PagesState);
    const pageData = pagesState.index;

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageData.meta.title}</title>
                <meta name="description" content={pageData.meta.description}/>
                <meta name="keywords" content={pageData.meta.keywords}/>
                <link rel={'canonical'} href={'https://coolhire.co'} />
            </Helmet>
            <Row>
                <Col>
                    <HomeCarousel />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1 className="section-title">{pageData.body.title}</h1>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={8} lg={8}>
                    <h4 style={{paddingTop: '20px'}}>{pageData.body.smallTitle}</h4>
                    <span className="text-size-medium" style={{paddingRight: '20px'}}>{ReactHtmlParser(pageData.body.content)}</span>
                </Col>
                <Col>
                    <Row>
                        <div className="icons-box style-3 icons-bg">
                            <div className="flex-row fx-col-2">
                                {
                                    pageData.serviceBoxes.map(service => {
                                        return (
                                            <Col sm={12} md={12} lg={12} key={service.title}>
                                                <ItemBox data={service} />
                                            </Col>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </Row>
                </Col>
            </Row>
        </Fragment>
    )
}

export default HomePage