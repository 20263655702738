import React, {useEffect} from 'react';
import {Row, Col, Alert, Button} from 'reactstrap';
import ErrorBoundary from "../components/Errors/ErrorBoundary";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faLock} from "@fortawesome/free-solid-svg-icons";

const BookingConfirmed = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    const {id, token} = props;

    return (
        <ErrorBoundary>
            <Row>
                <Col style={{textAlign: 'center'}}>
                    <FontAwesomeIcon icon={faCheckCircle} style={{color: 'green', fontSize: '100px', marginBottom: '20px'}} />
                    <h3>Booking Request Received!</h3>
                    <h5>{id}</h5>
                </Col>
                <Col style={{textAlign: 'center', paddingTop: '20px'}}>
                    <Alert color={'info'}>
                        <h5>Your booking is not guaranteed until a deposit payment had been made!</h5>
                        <a href={`https://coolhire.checkfront.com/reserve/booking/${id}?token=${token}&view=pay`}><Button color={'warning'}><FontAwesomeIcon icon={faLock} /> make a payment and secure your booking</Button></a>
                    </Alert>
                    <p>Thank you for your booking, it has been received by our system and a preliminary reservation in the diary has been made. Your booking reference is {id}!</p>

                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <p><strong>In order to secure your booking you need to make a payment on the account.</strong> You can do this now by clicking the button above. You will receive an email to the billing contact address shortly as a reminder too. In the event of high demand for a booking we reserve the right to cancel a booking without deposit payments made.</p>
                    <h6>View your booking online</h6>
                    <p>You can access your booking from our website by clicking the 'View your booking' button at the top of the website or visiting https://coolhire.co/booking/view. All you need is the billing email address and the booking id. From here you can view your order, download a PDF invoice, make payments and check your delivery statuss.</p>
                    <h6>Contact us</h6>
                    <p>In the event of a mistake or issue with your booking please contact us on hello@coolhire.co or call us on 01452 270340. Please have your booking id and billing email address available as we will need these details to find you in our diary!</p>
                    <h4 style={{textAlign: 'center'}}>Thank you for booking with Cool Hire!</h4>
                </Col>
            </Row>
        </ErrorBoundary>
    )
}

export default BookingConfirmed