import Axios from "axios";

async function CheckfrontGetBookingSession(startDate, endDate, productId, county, discountCode) {
    try {
        const URL = `${process.env.REACT_APP_AWS_API_URL}/bookings/session`;
        const config = {
            headers: {
                'X-API-Key': process.env.REACT_APP_AWS_API_KEY
            }
        }
        const body = {
            startDate,
            endDate,
            productId,
            discountCode,
            county
        }
        const response = await Axios.post(URL, body, config)
        return response.data.booking
    }
    catch (err) {
        console.error(err.message)
        throw new Error(err)
    }
}

export default CheckfrontGetBookingSession