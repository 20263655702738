import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import {Col, Row} from "reactstrap";
import PCImage from '../../images/pricematch/pricematch.png'

const PriceMatch = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Price MatchInformation</title>
                <meta name="description" content={'Covid-19'}/>
            </Helmet>
            <Row>
                <Col>
                    <h1>Price Match Information</h1>
                </Col>
            </Row>
            <Row>
                <Col md={7}>
                    <p>No confusing jargon here, just handy information you should know.</p>
                    <p>At CoolHire.co we regularly check our competitor prices and are confident that we provide excellent value for service, but if you do find any of our competitors offering more cheaply the equivalent equipment online at the same postcode and for the same hire period cheaper, we promise to match their price.</p>
                    <p>We price match our competitors’ trailer hire to save you going elsewhere within Gloucester.</p>
                    <p>Some things you will need to have to meet our price match criteria:</p>
                    <ul>
                        <li>Website of the competitor or copy of the quotation.</li>
                        <li>Trailer size you require</li>
                        <li>Date you will require the trailer</li>
                        <li>How long the trailer hire is for</li>
                        <li>Postcode of the trailer placement</li>
                    </ul>
                    <p>Alternatively, hit the 'Price Match Guarantee' link on the enquiry page and complete the quick form.</p>
                    <h5>Terms & Conditions</h5>
                    <p>The price match must be validated by one of our team by telephone or online.</p>
                    <p>It must be an online, cash price and not an account or volume price. For account prices, please contact the office to speak to one of our team.</p>
                    <p>Our trailer price match will only be made on the hire price (Free gifts or giveaways are not included).</p>
                    <p>No other promotional offers can be used in conjunction with this offer.</p>
                    <p>We do not price match comparison websites.</p>
                </Col>
                <Col>
                    <img src={PCImage} alt={'Cool Hire Price Match'} />
                </Col>
            </Row>
        </Fragment>
    )
}

export default PriceMatch