import React, {Fragment, useState} from 'react';
import {Alert, Row, Col, Button, Form, FormGroup, Input, Label} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import SubmitContactForm from "../../functions/Forms/SubmitContactForm";
import IsLoading from "../Global/IsLoading";
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();

const ContactForm = () => {
    const [ShowAlert, setAlert] = useState({show: false, color: 'dark', message: ''});
    const [FormData, setFormData] = useState({});
    const [SentForm, setSentForm] = useState(false);
    const [FormLoading, setFormLoading] = useState(false);

    const OnChange = (event) => {
        const target = event.target;
        setFormData(prevState => ({...prevState, [target.name]: target.value}))
    };

    const OnSubmit = async (event) => {
        event.preventDefault();
        recaptchaRef.current.execute();
        setFormLoading(true);
        try {
            await SubmitContactForm(FormData);
            setSentForm(true);
        }
        catch (error) {
            console.error(error);
            setAlert({show: true, color: 'danger', message: `There was an error submitting the form. Please call us on: 01452 270340.`})
        }
        setFormLoading(false);
    };

    if (SentForm) {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1>Thank you!</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Your message has been sent, please allow up to 2 working days for us to respond.</p>
                    </Col>
                </Row>
            </Fragment>
        )
    }
    else if (FormLoading) {
        return (
            <IsLoading />
        )
    }
    return (
        <Form onSubmit={OnSubmit}>
            <h2 className="section-title">Contact Form</h2>
            <p>Get in touch with the details on the left or use the form below! We aim to respond within 2 working days.
                If your requirement is immediate please call us on <a href="tel:01452270340">01452 270340</a> or <a href="tel:07482383863">07482 838 363</a>.</p>
            <Row>
                <Col>
                    <Alert isOpen={ShowAlert.show} color={ShowAlert.color} toggle={() => setAlert(prevState => ({...prevState, show: false}))}>
                        {ShowAlert.color === 'success' ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimesCircle} />} {ShowAlert.message}
                    </Alert>
                </Col>
            </Row>
            <Row form>
                <Col sm={12} md={6} lg={6}>
                    <FormGroup>
                        <Label className={'required'} for="name">Your Name</Label>
                        <Input type="text" name="name" id="name" placeholder="Your Name" onChange={OnChange} required/>
                    </FormGroup>
                </Col>
                <Col sm={12} md={6} lg={6}>
                    <FormGroup>
                        <Label className={'required'} for="email">Contact Email Address</Label>
                        <Input type="email" name="email" id="email" placeholder="Your email address" onChange={OnChange} required/>
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col sm={12} md={6} lg={6}>
                    <FormGroup>
                        <Label className={'required'} for="telephone">Contact Telephone Number</Label>
                        <Input type="telephone" name="telephone" id="telephone" placeholder="Your telephone number" onChange={OnChange} required/>
                    </FormGroup>
                </Col>
                <Col sm={12} md={6} lg={6}>
                    <FormGroup>
                        <Label for="address">Address</Label>
                        <Input type="text" name="address" id="address" placeholder="Your address" onChange={OnChange}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col sm={12} md={12} lg={12}>
                    <FormGroup>
                        <Label className={'required'} for="message">Your Message</Label>
                        <Input type="textarea" name="message" id="message" placeholder="Your message to us" onChange={OnChange} required/>
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col sm={12} md={12} lg={12}>
                    <p>By submitting this form you are happy for Cool Hire Ltd to use the information above to contact you about your enquiry. This information may be stored for the purposes of enquiry tracking and general business records.</p>
                </Col>
            </Row>
            <Row form>
                <Col sm={12} md={12} lg={12}>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT}
                    />
                    <Button type={'submit'} color={'primary'}><FontAwesomeIcon icon={faPaperPlane}/> Send to Cool Hire</Button>
                </Col>
            </Row>
        </Form>
    )
}

export default ContactForm