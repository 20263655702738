import React from 'react';
import {Link} from 'react-router-dom';

const Error404 = (props) => {
    document.title = 'Page Not Found';
    return (
        <div className="inner-404">
            <h1>Sorry, {props.location.pathname} cannot be found</h1>
            <h4>We're sorry, but we can't find the page ({props.location.pathname}) you were looking for.</h4>
            <p>It's probably some thing we've done wrong but now we know about it and we'll try to fix it.</p>
            <p>Go <Link to={'/'} className="text-link2">Home</Link></p>
        </div>
    )
}
export default Error404