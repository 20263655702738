import React, {Fragment} from 'react';
import {Row, Col, Alert} from 'reactstrap';
import ServiceCarousel from "./PageComponents/ServiceCarousel";
import ServiceBullets from "./PageComponents/ServiceBullets";
import ServiceNotBookable from "./ServiceCatchResponses/ServiceNotBookable";
import {Helmet} from "react-helmet";
import BookTrailer from "./EnquiryBooking/BookTrailer";
import BookPortables from "./EnquiryBooking/BookPortables";
import TrailerSizesTable from "./PageComponents/TrailerSizesTable";
import CheckfrontCheckAvailability from "./CheckfrontBooking/CheckfrontCheckAvailability";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import ReactHtmlParser from "react-html-parser";

const ServiceView = (props) => {
    const service = props.service;

    if (service.development && process.env.NODE_ENV === 'production') {
        return <Alert color={'info'}><FontAwesomeIcon icon={faExclamationCircle} /> This product isn't available in the current mode. Please select a different product.</Alert>
    }

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{service.meta.pageTitle}</title>
                <meta name="description" content={service.meta.description}/>
                <meta name="keywords" content={service.meta.keywords}/>
            </Helmet>
            <Row>
                <Col lg={8} md={8} sm={12}>
                    <main id={'main'}>
                        <h1>{service.friendlyName}</h1>
                        <ServiceCarousel imageData={service.content.images} productName={service.friendlyName} productId={service.id}/>
                        <br/>
                        <h3>{service.content.title}</h3>
                        {ReactHtmlParser(service.content.description)}
                        <ServiceBullets bullets={service.content.bullets}/>
                        <h4>Features &amp; Benefits</h4>
                        <p>{service.content.features}</p>
                        {
                            service.serviceType === 'trailer' && <TrailerSizesTable />
                        }
                    </main>
                </Col>
                <Col>
                    <aside id={'sidebar'} className={'sbr'} style={{height: '100%'}}>
                        {
                            service.bookable === true ?
                                (service.serviceType === 'trailer' ? (service.checkfront ? <CheckfrontCheckAvailability service={service} />
                                    : <BookTrailer service={service} />)
                                    :
                                    (service.serviceType === 'portables' ? <BookPortables service={service} />
                                    :
                                    <ServiceNotBookable />
                                    )
                                )
                                :
                                <ServiceNotBookable/>
                        }
                    </aside>
                </Col>
            </Row>
        </Fragment>
    )
}

export default ServiceView;