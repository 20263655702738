import React from 'react';
import SizeGuide from '../../../images/trailer-size-guide.png';

const TrailerSizesTable = () => {
    return (
        <div style={{paddingBottom: '20px'}}>
            <h4>Available Trailer Sizes</h4>
            <img src={SizeGuide} alt={'Trailer Sizing Guide'}/>
        </div>
    )
}

export default TrailerSizesTable