import React from 'react';
import {Container, Row, Col, Alert} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

const LoadingError = (props) => {
    return (
        <Container>
            <Row>
                <Col>
                    <Alert color={'warning'}>
                        <h4 className="alert-heading"><FontAwesomeIcon icon={faExclamationTriangle} /> An error has occurred!</h4>
                        <p>We are sorry but the site has encountered an error and cannot be displayed.<br />Our technical team have been informed and are working on this now.</p>
                        <p>Please reload the page or try again later.</p>
                        <hr />
                        <p className="mb-0">
                            {props.error}
                        </p>
                    </Alert>
                </Col>
            </Row>

        </Container>
    )
}

export default LoadingError;