import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import PrettyDate from "../../Global/PrettyDate";
import PrettyItemStatus from "./PrettyItemStatus";
import NumberFormat from "react-number-format";

const BookingItemsTable = (props) => {
    const {items} = props;

    const columns = [
        {
            dataField: 'id',
            text: 'Payment ID',
            formatter: (cell, row) => {
                console.log(row)
                return cell
            }
        },
        {
            dataField: 'date',
            text: 'Date Paid',
            headerStyle: {width: '150px'},
            formatter: (cell) => {
                return (<PrettyDate date={cell} />)
            }
        },
        {
            dataField: 'status',
            text: 'Status',
            headerStyle: {width: '100px'},
            formatter: (cell) => {
                return (<PrettyItemStatus status={cell} />)
            }
        },
        {
            dataField: 'gateway_id',
            text: 'Gateway ID',
            headerStyle: {width: '120px'},
            formatter: (cell) => {
                return (cell)
            }
        },
        {
            dataField: 'amount',
            text: 'Amount',
            headerStyle: {width: '100px'},
            formatter: (cell) => {
                return (<NumberFormat value={cell} displayType={'text'}
                                      decimalSeparator={'.'} prefix={'£'} decimalScale={2}
                                      fixedDecimalScale={true}/>)
            }
        }];


    return (
        <BootstrapTable keyField='id' data={items} columns={columns} noDataIndication={'No Transactions to Display'} />
    )

}

export default BookingItemsTable