import React, {Fragment} from 'react';
import {Row, Col, Card, CardImg, CardBody, CardText, CardTitle,} from 'reactstrap';
import {Helmet} from "react-helmet";
import {useRecoilValue} from "recoil";
import {GalleryState} from "../store/store";

const GalleryPage = () => {
    const gallery = useRecoilValue(GalleryState);

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Cool Hire Image Gallery</title>
                <meta name="description" content={'View the image gallery at Cool Hire'}/>
                <meta name="keywords" content={'image,gallery,cool,hire'}/>
            </Helmet>
            <Row>
                <Col>
                    <h1 className={'section-title'}>Gallery</h1>
                    <p>A selection of various product images.</p>
                </Col>
            </Row>
            <Row>
                {
                    gallery.map(item => {
                        return (
                            <Col sm={12} md={4} lg={4} key={item.id} style={{marginBottom: '10px'}}>
                                <Card>
                                    <CardImg top width="100%" src={`${process.env.REACT_APP_AWS_S3_BUCKET}/Gallery/${item.id}`} alt={item.title}/>
                                    <CardBody>
                                        <CardTitle>{item.content.title}</CardTitle>
                                        <CardText>{item.content.description}</CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
        </Fragment>
    )
};

export default GalleryPage