import Axios from "axios";

async function GetBooking(bookingId, email) {

    const URL = `${process.env.REACT_APP_AWS_API_URL}/bookings/view`;
    const config = {
        headers: {
            'X-API-Key': process.env.REACT_APP_AWS_API_KEY
        }
    }
    const data = {bookingId, email}

    return await Axios.post(URL, data, config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            throw new Error(error.message)
        })
}

export default GetBooking;