import React, {Fragment}  from 'react';
import {Container} from 'reactstrap';
import JotformModal from "../../components/Global/JotformModal";

const MainHeader = () => {

    return (
        <Fragment>
            <header id="header" className="header style-2">
                <div className="pre-header">
                    <div className="container extra-width-2">
                        <div className="flex-row flex-justify">
                            <span style={{paddingTop: '5px'}}>Gloucestershire's portable cooling & heating specialists</span>
                            <ul className="menu-list">
                                <li><JotformModal /></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="top-header">
                    <Container className="extra-width-2">
                        <ul className="contact-info flex-justify flex-center">
                            <li className="info-item">
                                <i className="licon-at-sign" />
                                <div className="item-info">
                                    <span>E-mail:</span>
                                    <a href="mailto:hello@coolhiretrailers.co.uk">hello@coolhiretrailers.co.uk</a>
                                </div>
                            </li>
                            <li className="info-item">
                                <i className="licon-telephone2" />
                                <div className="item-info">
                                    <span>Friendly Service</span>
                                    <h4><a href="tel:01452270340">01452 270340</a></h4>
                                </div>
                            </li>
                            <li className="info-item">
                                <i className="licon-telephone2" />
                                <div className="item-info">
                                    <span>24 Hour Emergency</span>
                                    <h4><a href="tel:07482838363">07482 838 363</a></h4>
                                </div>
                            </li>
                        </ul>
                    </Container>
                </div>
            </header>
        </Fragment>
    )
}

export default MainHeader