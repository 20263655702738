import React, {Fragment, useState} from 'react';
import {Row, Col, Input, Form, FormGroup, Button, Alert} from 'reactstrap';
import {Link} from 'react-router-dom';
import {useRecoilState} from "recoil";
import {CurrentBooking} from "../store/store";
import ReCAPTCHA from "react-google-recaptcha";
import SubmitEnquiryRequest from "../functions/Forms/SubmitEnquiryRequest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faInfo} from "@fortawesome/free-solid-svg-icons";
import IsLoading from "../components/Global/IsLoading";
const recaptchaRef = React.createRef();

const EnquiryPage = () => {
    const [booking, setBooking] = useRecoilState(CurrentBooking);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({show: false, message: '', color: '', icon: <FontAwesomeIcon icon={faInfo} />})

    const OnSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        try {
            // removed due to timeout bug
            //recaptchaRef.current.execute();
            await SubmitEnquiryRequest(booking);
            setBooking((prevState) => ({...prevState, sent: true}))
        }
        catch (err) {
            setMessage({show: true, message: err.message, color: 'danger', icon: <FontAwesomeIcon icon={faExclamationTriangle} />})
        }
        setLoading(false)
    }

    const OnChange = (event) => {
        const {name, value} = event.target;
        setBooking((prevState) => ({...prevState, [name] : value}));
    }

    if (!booking || !booking.service) {
        return (
            <Fragment>
                <h2>Something went wrong</h2>
                <p>Please select a rental service to continue with your booking.</p>
                <p>You may have encountered this page by accident, if so please go back and try again.</p>
            </Fragment>
        )
    }

    if (booking.sent) {
        return (
            <Fragment>
                <Row>
                    <Col>
                        <h1>Thank you!</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Your message has been sent, please allow up to 24 hours for us to respond.</p>
                        <p>For urgent enquiries please call us on <a href={'tel:01452270340'}>01452 270 340</a>.</p>
                    </Col>
                </Row>
            </Fragment>
        )
    }

    if (loading) {
        return <IsLoading />
    }

    return (
        <Fragment>
            <Form onSubmit={OnSubmit}>
                <Row>
                    <Col>
                        <h2>Your Enquiry for {booking.service.friendlyName}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Alert isOpen={message.show} color={message.color}>{message.icon} {message.message}</Alert>
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'right'}}>
                        <h6>Drop Off Date:</h6>
                    </Col>
                    <Col>
                        {new Date(booking.startDate).toLocaleDateString('en-gb')}
                    </Col>
                    <Col style={{textAlign: 'right'}}>
                        <h6>Collection Date:</h6>
                    </Col>
                    <Col>
                        {new Date(booking.endDate).toLocaleDateString('en-gb')}
                    </Col>
                </Row>
                {
                    booking.service.serviceType === 'trailer' &&
                    <Fragment>
                        <Row>
                            <Col style={{textAlign: 'right'}}>
                                <h6>Number of Trailers:</h6>
                            </Col>
                            <Col>
                                {booking.units}
                            </Col>
                            <Col style={{textAlign: 'right'}}>
                                <h6>Trailer Size:</h6>
                            </Col>
                            <Col>
                                {booking.size}
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'right'}}>
                                <h6>Unit Temperature:</h6>
                            </Col>
                            <Col>
                                {booking.temperature}
                            </Col>
                            <Col style={{textAlign: 'right'}}>
                                <h6>Unit Use Description:</h6>
                            </Col>
                            <Col>
                                {booking.use}
                            </Col>
                        </Row>
                    </Fragment>
                }
                {
                    booking.service.serviceType === 'portables' &&
                    <Fragment>
                        <Row>
                            <Col style={{textAlign: 'right'}}>
                                <h6>Number of AC units:</h6>
                            </Col>
                            <Col>
                                {booking.units}
                            </Col>
                            <Col style={{textAlign: 'right'}}>
                                <h6>Main Purpose:</h6>
                            </Col>
                            <Col>
                                {booking.purpose}
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign: 'right'}} >
                                <h6>Primary Mode:</h6>
                            </Col>
                            <Col>
                                {booking.mode}
                            </Col>
                            <Col style={{textAlign: 'right'}}>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </Fragment>
                }
                <Row>
                    <Col>
                        <h3>Delivery Details</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={10}>
                        <label className="required">Delivery Address</label>
                        <Input type={'text'} name={'deliveryAddress'} onChange={OnChange} required/>
                    </Col>
                    <Col md={2}>
                        <label className="required">Delivery Postcode</label>
                        <Input type={'text'} name={'deliveryPostcode'} onChange={OnChange} required/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>Your Details</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label className="required">Your Name</label>
                        <Input type={'text'} name={'name'} onChange={OnChange} required/>
                    </Col>
                    <Col>
                        <label>Company Name</label>
                        <Input type={'text'} name={'company'} onChange={OnChange}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label className="required">Email</label>
                        <Input type={'email'} name={'email'} onChange={OnChange} required/>
                    </Col>
                    <Col>
                        <label className="required">Telephone Number</label>
                        <Input type={'text'} name={'telephone'} onChange={OnChange} required/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>Getting in Touch</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label className="required">I'd prefer to be contacted by:</label>
                        <div className="input-wrapper">
                            <input type="radio" name="preferredContactMethod" id="radio-1" value="Email" onChange={OnChange}/>
                            <label htmlFor="radio-1">Email</label>
                            <input type="radio" name="preferredContactMethod" id="radio-2" value="Phone" onChange={OnChange}/>
                            <label htmlFor="radio-2">Phone</label>
                            <input type="radio" name="preferredContactMethod" id="radio-3" value="Both" onChange={OnChange}/>
                            <label htmlFor="radio-3">Phone</label>
                        </div>
                    </Col>
                    <Col>
                        <label className="required">How did you hear about us?</label>
                        <FormGroup>
                            <Input type="select" name="recommended" id="units" defaultValue={'default'} onChange={OnChange} required>
                                <option value={'default'} disabled>-- Select One --</option>
                                <option>Recommendation</option>
                                <option>Search Engine</option>
                                <option>Contacted by Cool Hire</option>
                                <option>Saw at another event</option>
                                <option>Flyer/Business Card</option>
                                <option>Previous Customer/Commercial Partner</option>
                                <option>Other</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <label>Additional enquiry comments?</label>
                            <Input type="textarea" name="comments" id="comments" onChange={OnChange} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>By submitting this form you confirm acceptance of the <Link to={'/info/terms'} target={'_blank'}>Terms & Conditions</Link>.</p>
                        <p>
                            You also confirm that Cool Hire can hold your details for the purpose of your enquiry and any future bookings.<br />
                            Cool Hire will not pass your details to 3rd parties without your prior consent (unless required as part of any future bookings).
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT}
                        />
                        <Button type={'submit'}>Submit</Button>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    )
}

export default EnquiryPage