import React, {Fragment, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faCheckCircle, faQuestionCircle,
    faSync,
    faThumbsDown,
    faThumbsUp,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import ConvertStringToDate from "../../../functions/Utils/ConvertStringToDate";
import {useSetRecoilState} from "recoil";
import {CurrentBooking} from "../../../store/store";
import PrettyDateFromString from "../../Global/PrettyDateFromString";

const CheckfrontAvailabilityResults = (props) => {
    const history = useHistory();
    const {availability, setAvailability} = props;
    const setGlobalAvailability = useSetRecoilState(CurrentBooking);
    const [disableBtns, setDisableBtns] = useState(false);

    const ResetAvailability = () => {
        setGlobalAvailability(undefined);
        setAvailability(undefined);
    }

    const ContinueBooking = () => {
        setDisableBtns(true);
        setGlobalAvailability(availability);
        history.push('/booking');
    }

    if (availability.availability.isAvailable === true) {
        const startDate = Object.keys(availability.availability.dates[0]);
        const endDate = Object.keys(availability.availability.dates[availability.availability.dates.length - 1]);

        return (
            <Fragment>
                <h4 style={{color: 'green', textAlign: 'center'}}><FontAwesomeIcon icon={faThumbsUp}/> Available</h4>
                <p>We have full availability for the dates you have selected.</p>
                <p>For pricing and to make a booking request click the button below. If you want to check other dates
                    select Check Availability Again.</p>
                <div className="table-type-2">
                    <table>
                        <tbody>
                        <tr>
                            <th>Size</th>
                            <td>{availability.item.name}</td>
                        </tr>
                        <tr>
                            <th>Drop Off Date</th>
                            <td><PrettyDateFromString date={startDate[0]} /></td>
                        </tr>
                        <tr>
                            <th>Collection Date</th>
                            <td><PrettyDateFromString date={endDate[0]} /></td>
                        </tr>
                        </tbody>
                    </table>
                    <small><strong><FontAwesomeIcon icon={faQuestionCircle} /> Need more than one?</strong> Call (<a href={'tel:01452270340'}>01452 270 340</a>) or email (<a href={'mailto:hello@coolhire.co'}>hello@coolhire.co</a>) us to check availability and offers.</small>
                </div>

                <br />
                <Button color={'primary'} onClick={ContinueBooking} disabled={disableBtns} block><FontAwesomeIcon icon={faArrowRight}/> Get Your Price & Request Booking</Button>
                {' '}
                <Button color={'secondary'} onClick={ResetAvailability} disabled={disableBtns} block><FontAwesomeIcon icon={faSync}/> Check Availability Again</Button>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <h4 style={{textAlign: 'center'}}><FontAwesomeIcon icon={faThumbsDown}/> Sorry</h4>
            <p>We don't have availability for the dates you have selected.</p>
            <p>Below is a breakdown of the dates we have availability to help you plan. Use the button below to select
                new dates or different size service and check again.</p>
            <Button color={'primary'} onClick={ResetAvailability} block><FontAwesomeIcon icon={faSync}/> Check Again</Button>
            <br/>
            <div className="table-type-1">
                <table>
                    <tbody>
                    <tr>
                        <th>Date</th>
                        <th>Availability</th>
                    </tr>
                    {
                        availability.availability.dates.map((item) => {
                            const array1 = Object.keys(item)
                            const array2 = Object.values(item)
                            const date = ConvertStringToDate(array1[0])

                            return (
                                <tr key={array1[0]}>
                                    <td>
                                        {date.toLocaleDateString('en-GB')}
                                    </td>
                                    <td>
                                        {array2[0] ? <FontAwesomeIcon style={{color: 'green'}} icon={faCheckCircle}/> :
                                            <FontAwesomeIcon style={{color: 'red'}} icon={faTimesCircle}/>}
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default CheckfrontAvailabilityResults