import React from 'react'

const PrettyDate = (props) => {
    const {date} = props;

    if (!date) {
        return <p>Invalid Date</p>
    }

    let formatted = new Date(0);
    formatted.setUTCSeconds(date)

    return (formatted.toLocaleDateString('en-GB'))
}

export default PrettyDate