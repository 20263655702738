import React from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleRight, faIcicles, faTemperatureLow} from "@fortawesome/free-solid-svg-icons";

const ItemBox = (props) => {
    return (
        <div className="icons-wrap" style={{textAlign: 'center'}}>
            <div className="icons-item">
                <div className="item-box">
                    <h1 className="icons-box-title"><Link to={props.data.link}>{props.data.type === 'trailer' ? <FontAwesomeIcon icon={faTemperatureLow} /> : <FontAwesomeIcon icon={faIcicles} />}</Link></h1>
                    <h3 className="icons-box-title"><Link to={props.data.link}>{props.data.title}</Link></h3>
                    <p>{props.data.body}</p>
                    <br />
                    <Link to={props.data.link} className="btn btn-small btn-style-5"><FontAwesomeIcon icon={faAngleDoubleRight} /> {props.data.btnText}</Link>
                </div>
            </div>
        </div>
    )
};

export default ItemBox