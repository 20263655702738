import React, {useEffect, useState} from 'react';
import {Router} from 'react-router-dom';
import {useRecoilState} from "recoil";
import {Container} from "reactstrap";
import { createBrowserHistory } from 'history';
import MainHeader from './pages/Global/MainHeader';
import MainMenu from "./pages/Global/MainMenu";
import MainFooter from './pages/Global/MainFooter';
import MainRoutes from "./routes/MainRoutes";
import IsLoading from "./components/Global/IsLoading";
import {ToastContainer} from "react-toastify";

import ReactGA from 'react-ga';

import GetSiteData from "./functions/Requests/GetSiteData";
import {CarouselState, GalleryState, PagesState, ServiceState} from './store/store';
import LoadingError from "./pages/Errors/LoadingError";
import ScrollToTop from "./components/Global/ScrollToTop";


// Initialize google analytics page view tracking
const history = createBrowserHistory();
ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_CODE);
history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const App = () => {
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState({error: false, message: null})
    // eslint-disable-next-line
    const [serviceData, setServices] = useRecoilState(ServiceState);
    // eslint-disable-next-line
    const [pagesData, setPages] = useRecoilState(PagesState);
    // eslint-disable-next-line
    const [galleryData, setGallery] = useRecoilState(GalleryState);
    // eslint-disable-next-line
    const [carouselData, setCarousel] = useRecoilState(CarouselState);

    const GetData = async () => {
        setLoading(true);
        try {
            const data = await GetSiteData();
            data.map((item) => {
                switch (item.type) {
                    case ('page'):
                        setPages((prevState) => ({...prevState, [item.id]: item }))
                        break;

                    case ('service'):
                        setServices(prevState => prevState.concat(item));
                        break;

                    case ('gallery'):
                        setGallery(prevState => prevState.concat(item));
                        break;

                    case ('carousel'):
                        setCarousel(prevState => prevState.concat(item));
                        break;

                    default:
                        break;
                }
                return null;
            })
        }
        catch (error) {
            setError({error: true, message: error.message})
            setLoading(false)
        }
        setLoading(false);
    };

    useEffect(() => {
        GetData();
        // eslint-disable-next-line
    }, []);

    if (isLoading) {
        return (
            <IsLoading/>
        )
    }
    if (isError.error) {
        return (
            <LoadingError error={isError.message}/>
        )
    }
    return (
        <Router basename={process.env.PUBLIC_URL} history={history}>
            <ScrollToTop />
                <div id="wrapper" className="wrapper-container">
                    <nav id="mobile-advanced" className="mobile-advanced"/>
                    <MainHeader/>
                    <MainMenu />
                    <Container className="extra-width-2 content-container">
                        <MainRoutes />
                    </Container>
                    <MainFooter />
                </div>
            <ToastContainer />
        </Router>
    );
};

export default App;
