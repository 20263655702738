import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Form, FormGroup, FormText, Input, Alert} from "reactstrap";
import DatePicker from "react-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faExclamationCircle, faSpinner} from "@fortawesome/free-solid-svg-icons";
import CheckfrontAvailabilityCheck from "../../../functions/Checkfront/CheckfrontAvailabilityCheck";
import ConvertDateToString from "../../../functions/Utils/ConvertDateToString";
import ErrorBoundary from "../../Errors/ErrorBoundary";

const CheckfrontAvailabilityForm = (props) => {
    const {service, setAvailability} = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({show: false})

    const now = new Date();

    // minDate says we cant book within the next 3 days
    const minDate = now.setDate(now.getDate() + 3)

    // Sets the default end date 6 days from now (3minimum + 3 booking days)
    const plus6Days = new Date()
    plus6Days.setDate(plus6Days.getDate() + 6)

    // minEnd is needed to calculate the end date box correctly!
    const minEnd = new Date(minDate);

    // Weekday calculations for calendar
    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    // Form States
    const [startDate, setStartDate] = useState(new Date(minDate));
    const [endDate, setEndDate] = useState(new Date(plus6Days));
    const [formData, setFormData] = useState({startDate: minDate, endDate: endDate, service: props.service})

    const StartChange = (date) => {
        date = new Date(date);
        setStartDate(date);
        setFormData((prevState) => ({...prevState, startDate: date}));
    }

    const EndChange = (date) => {
        date = new Date(date);
        setEndDate(date)
        setFormData((prevState) => ({...prevState, endDate: date}));
    }

    const OnChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevState) => ({...prevState, [name]: value}));
    }

    const OnSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            // get checkfront data from API
            let startDate = new Date(formData.startDate)
            startDate = await ConvertDateToString(startDate);

            let endDate = new Date(formData.endDate)
            endDate = await ConvertDateToString(endDate);

            if (startDate > endDate) {
                throw new Error('date error')
            }

            setAvailability(await CheckfrontAvailabilityCheck(startDate, endDate, formData.productId, formData.email))
            // set the availability State (should trigger render to results page)
        }
        catch (err) {
            setLoading(false)
            if (err.message === 'date error'){
                setError({show: true, message: 'Drop Off date cannot be after Pick Up date. Please check the dates and try again.'})
            }
            else {
                console.error(err)
                setError({show: true, message: 'There was an issue checking availability. Please try again later.'})
            }
        }

    }

    return (
        <ErrorBoundary>
            <Alert color={'warning'} isOpen={error.show}><FontAwesomeIcon icon={faExclamationCircle} /> {error.message}</Alert>
            <Form onSubmit={OnSubmit}>
                <FormGroup>
                    <h6 className="required">Drop off date (weekday only)</h6>
                    <DatePicker
                        selected={startDate}
                        onChange={date => StartChange(date)}
                        selectsStart
                        minDate={minDate}
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        required
                        disabled={loading}
                        filterDate={isWeekday}
                    />
                    <FormText color="muted">We will confirm a drop off time with you closer to your reservation. If your event is over the weekend please select a date prior to the weekend.</FormText>
                </FormGroup>
                <FormGroup>
                    <h6 className="required">Pick up date (weekday only)</h6>
                    <DatePicker
                        selected={endDate}
                        onChange={date => EndChange(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={minEnd.setDate(startDate.getDate() + 3)}
                        dateFormat="dd/MM/yyyy"
                        required
                        disabled={loading}
                        filterDate={isWeekday}
                    />
                    <FormText color="muted">We will confirm a pick up time with you closer to your reservation. Your booking must be a minimum of 4 days.</FormText>
                </FormGroup>
                <FormGroup>
                    <h6 className="required">Size Options</h6>
                    <Input type="select" name="productId" id="productId" onChange={OnChange} required disabled={loading}>
                        <option value={''}>-- Select One --</option>
                        {
                            service.checkfront.map((service) => {
                                return (<option value={service.productId} key={service.productId}>{service.name}</option>)
                            })
                        }
                    </Input>
                    <FormText color="muted">See the sizing grid for details on each.</FormText>
                </FormGroup>
                <FormGroup>
                    <h6>Email Address</h6>
                    <Input type="email" name="email" id="email" onChange={OnChange} disabled={loading} />
                    <FormText color="muted">(Optional) - Used as per our <Link to={'/info/privacy'}>privacy policy</Link>.</FormText>
                </FormGroup>
                {
                    loading ?
                        <Button color={'primary'} block disabled><FontAwesomeIcon icon={faSpinner} spin /> Checking Availability ...</Button>
                        :
                        <Button type={'submit'} color={'primary'} block><FontAwesomeIcon icon={faArrowRight} /> Check Availability</Button>
                }
            </Form>
        </ErrorBoundary>
    )
}

export default CheckfrontAvailabilityForm