import React, {useState} from 'react';
import ErrorBoundary from "../../Errors/ErrorBoundary";
import CheckfrontAvailabilityResults from "./CheckfrontAvailabilityResults";
import CheckfrontAvailabilityForm from "./CheckfrontAvailabilityForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";

const CheckfrontCheckAvailability = (props) => {
    const {service} = props;
    const [availability, setAvailability] = useState(undefined);

    return (
        <ErrorBoundary>
            <h5><FontAwesomeIcon icon={faCalendar} /> Check Availability Online</h5>
            <p>Check availability and reserve your service with Cool Hire today using our handy online booking service.</p>
            <hr />
            {
                availability ? <CheckfrontAvailabilityResults availability={availability} setAvailability={setAvailability} /> : <CheckfrontAvailabilityForm service={service} setAvailability={setAvailability}/>
            }
        </ErrorBoundary>
    )
}

export default CheckfrontCheckAvailability