import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faMailBulk, faPhone, faThList} from "@fortawesome/free-solid-svg-icons";

const ServiceNotBookable = () => {
    return (
        <Fragment>
            <h4><FontAwesomeIcon icon={faClock} /> Availability &amp; Bookings</h4>
            <p>For booking and availability please contact us:</p>
            <h5><FontAwesomeIcon icon={faPhone} /> Telephone:</h5>
            <p><a href={"tel:01452270340"}>01452 270340</a><br />or<br /><a href={"tel:07482838363"}>07482 838 363</a> (24 hour emergency).</p>
            <h5><FontAwesomeIcon icon={faMailBulk} /> Email:</h5>
            <p><a href={'mailto:hello@coolhiretrailers.co.uk'}>hello@coolhiretrailers.co.uk</a></p>
            <h5><FontAwesomeIcon icon={faThList} /> Contact Form:</h5>
            <p><Link to={'/contact-us'}>Click Here</Link></p>
        </Fragment>
    )
}

export default ServiceNotBookable;