import React, {useState} from 'react';
import {Form, Row, Alert, Col, Input, Button, Label, FormGroup} from 'reactstrap';
import {useSetRecoilState} from "recoil";
import {ViewedBooking} from "../../store/store";
import ErrorBoundary from "../Errors/ErrorBoundary";
import GetBooking from "../../functions/Bookings/GetBooking";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamationTriangle, faSpinner} from "@fortawesome/free-solid-svg-icons";

const ViewBookingForm = () => {

    const [loading, setLoading] = useState(false)
    const setBooking = useSetRecoilState(ViewedBooking);
    const [error, setError] = useState({show: false})
    const [formData, setFormData] = useState({})

    const onSubmit = async (event) => {
        event.preventDefault()

        setLoading(true)
        try {
            const booking = await GetBooking(formData.bookingId, formData.email);
            if (!booking.success) {
                setError({show: true, message: booking.message})
                setLoading(false)
                return;
            }
            setBooking(booking)
            setLoading(false)
        }
        catch (err) {
            setError({show: true, message: err.message})
            setLoading(false)
        }
    }

    const onChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevState) => ({...prevState, [name]: value}))
    }

    return (
        <ErrorBoundary>
            <Row>
                <Col>
                    <h1>View your booking</h1>
                    <p>Check your booking with us. Simply input the Booking ID and the Email address you used to complete your booking.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Alert isOpen={error.show} toggle={() => setError({show: false})} color={'warning'}><FontAwesomeIcon icon={faExclamationTriangle} /> {error.message} </Alert>
                </Col>
            </Row>
            <Form onSubmit={onSubmit}>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label className={'required'} for="bookingId">Your Booking ID</Label>
                            <Input type={'text'} name={'bookingId'} onChange={onChange} required />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label className={'required'} for="email">Your Email</Label>
                            <Input type={'email'} name={'email'} onChange={onChange} required />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={{size: 4, offset: 4}}>
                        {
                            loading ?
                                <Button disabled style={{width: '100%'}}><FontAwesomeIcon icon={faSpinner} /> Loading</Button>
                                :
                                <Button style={{width: '100%'}} color={'primary'}><FontAwesomeIcon icon={faCheck} /> View my Booking!</Button>
                        }
                    </Col>
                </Row>
            </Form>
        </ErrorBoundary>
    )
}

export default ViewBookingForm