import Axios from "axios";

async function CheckfrontAvailabilityCheck(startDate, endDate, productId) {
    const URL = `${process.env.REACT_APP_AWS_API_URL}/availability`;
    const config = {
        headers: {
            'X-API-Key': process.env.REACT_APP_AWS_API_KEY
        }
    }
    const data = {startDate, endDate, productId}

    return await Axios.post(URL, data, config)
        .then((response) => {
            return {availability: response.data.availability, item: response.data.item}
        })
        .catch((error) => {
            throw new Error(error.message)
        })
}

export default CheckfrontAvailabilityCheck