import React, {Fragment, useEffect, useState} from 'react';
import {useSetRecoilState} from "recoil";
import {Form, FormGroup, FormText, Input} from 'reactstrap';
import DatePicker from 'react-datepicker';
import {Link} from 'react-router-dom';
import {CurrentBooking} from "../../../store/store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";

const BookPortables = (props) => {
    const setBooking = useSetRecoilState(CurrentBooking);

    const now = new Date();
    const minDate = now.setDate(now.getDate() + 3)
    const [startDate, setStartDate] = useState(minDate);
    const [endDate, setEndDate] = useState(startDate);

    useEffect(() => {
        setBooking({startDate: minDate, endDate: minDate, service: props.service});
        // eslint-disable-next-line
    }, [])

    const StartChange = (date) => {
        setStartDate(date)
        setBooking((prevState) => ({...prevState, startDate: date}));
    }

    const EndChange = (date) => {
        setEndDate(date)
        setBooking((prevState) => ({...prevState, endDate: date}));
    }

    const OnChange = (event) => {
        const {name, value} = event.target;
        setBooking((prevState) => ({...prevState, [name]: value}));
    }

    return (
        <Fragment>
            <h5>Book Portable Air Conditioning</h5>
            <p>Book Portable Air Conditioning with Cool Hire today.</p>
            <p>Fill in the details below and we will contact you within 24 hours.</p>
            <Form>
                <FormGroup>
                    <h6 className="required">Drop off date</h6>
                    <DatePicker
                        selected={startDate}
                        onChange={date => StartChange(date)}
                        selectsStart
                        minDate={minDate}
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        required
                    />
                    <FormText color="muted">We will confirm a drop off time via email.</FormText>
                </FormGroup>
                <FormGroup>
                    <h6 className="required">Pick up date</h6>
                    <DatePicker
                        selected={endDate}
                        onChange={date => EndChange(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        dateFormat="dd/MM/yyyy"
                        required
                    />
                    <FormText color="muted">We will confirm a pick up time via email.</FormText>
                </FormGroup>
                <FormGroup>
                    <h6 className="required">Number of AC Units required</h6>
                    <Input type="select" name="units" id="units" defaultValue={'default'} onChange={OnChange} required>
                        <option value={'default'} disabled>-- Select One --</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                        <option>11+</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <h6 className="required">Units general purpose</h6>
                    <Input type="select" name="purpose" id="units" defaultValue={'default'} onChange={OnChange} required>
                        <option value={'default'} disabled>-- Select One --</option>
                        <option>Office Use</option>
                        <option>Home Use</option>
                        <option>Server Room Use</option>
                        <option>Breakdown Backup</option>
                        <option>Emergency Requirement</option>
                        <option>Other/Combination of Above</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <h6 className="required">Unit Mode</h6>
                    <Input type="select" name="mode" id="units" defaultValue={'default'} onChange={OnChange} required>
                        <option value={'default'} disabled>-- Select One --</option>
                        <option>Cooling</option>
                        <option>Heating</option>
                        <option>Both</option>
                    </Input>
                </FormGroup>
                <Input type={'button'} tag={Link} to={'/enquiry'} className={'btn btn-style-5'}><FontAwesomeIcon icon={faArrowRight} /> Continue</Input>
            </Form>
        </Fragment>

    )
}

export default BookPortables