import React, {Fragment, useState} from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import MobileMenuItems from "../../components/Global/MobileMenuItems";

const MobileMenu = (props) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
        <Fragment>
            <button type="button" id="responsive-nav-button" className="responsive-nav-button" onClick={toggle}/>
            <Modal isOpen={modal} toggle={toggle} >
                <ModalHeader style={{marginBottom: '0px'}} toggle={toggle}>
                    <h2 style={{marginBottom: '0px'}}>Cool Hire</h2>
                    <h6 style={{marginBottom: '0px'}} >Mobile Refrigeration Specialists</h6>
                </ModalHeader>
                <ModalBody>
                    <MobileMenuItems toggle={toggle} />
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default MobileMenu