import React, {useState} from 'react';
import {ModalBody, ModalHeader, Modal, Button, ModalFooter} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookOpen} from "@fortawesome/free-solid-svg-icons";

const JotformModal = () => {
    const JotformUrl = `https://form.jotform.com/221002406727040`;
    const [isOpen, setOpen] = useState(false)

    const toggle = () => setOpen(!isOpen)

    return (
        <>
            <Button size={'sm'} onClick={toggle}><FontAwesomeIcon icon={faBookOpen} /> Booking Form</Button>
            <Modal isOpen={isOpen} toggle={toggle} size={'xl'}>
                <ModalHeader toggle={toggle}>
                    Booking Form
                </ModalHeader>
                <ModalBody>
                    <p>Complete the below form to make a quick enquiry with Cool Hire.</p>
                    <iframe
                        title={'Quick Enquiry Form'}
                        src={JotformUrl}
                        width={'100%'}
                        height={'500px'}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color={'primary'} onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default JotformModal