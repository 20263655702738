import React, {Fragment} from 'react';
import {Row, Col} from 'reactstrap';
import {Helmet} from "react-helmet";

const Privacy = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Privacy policy</title>
                <meta name="description" content={'Privacy policy'}/>
            </Helmet>

            <Row>
                <Col>
                    <h1>Privacy Policy</h1>
                </Col>
            </Row>

            <Row>
                <Col><p>To view the latest copy of our privacy policy please download them from this link: <a href={'/pdf/privacy.pdf'}>Privacy</a>.</p>
                </Col>
            </Row>
        </Fragment>
    )
}

export default Privacy;

