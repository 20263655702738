import React, {Fragment, useEffect, useState} from 'react';
import {useSetRecoilState} from "recoil";
import {Form, FormGroup, FormText, Input} from 'reactstrap';
import DatePicker from 'react-datepicker';
import {CurrentBooking} from "../../../store/store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from 'react-router-dom';

const BookTrailer = (props) => {
    const history = useHistory();
    const setBooking = useSetRecoilState(CurrentBooking);

    const now = new Date();

    // minDate says we cant book within the next 3 days
    const minDate = now.setDate(now.getDate() + 3)

    // Sets the default end date 6 days from now (3minimum + 3 booking days)
    const plus6Days = new Date()
    plus6Days.setDate(plus6Days.getDate() + 6)

    // minEnd is needed to calculate the end date box correctly!
    const minEnd = new Date(minDate);

    // Form States
    const [startDate, setStartDate] = useState(new Date(minDate));
    const [endDate, setEndDate] = useState(new Date(plus6Days));
    const [formData, setFormData] = useState({startDate: minDate, endDate: endDate, service: props.service})

    useEffect(() => {
        setBooking({startDate: minDate, endDate: minDate, service: props.service});
        // eslint-disable-next-line
    }, []);

    const StartChange = (date) => {
        date = new Date(date);
        setStartDate(date);
        setFormData((prevState) => ({...prevState, startDate: date}));
    }

    const EndChange = (date) => {
        date = new Date(date);
        setEndDate(date)
        setFormData((prevState) => ({...prevState, endDate: date}));
    }

    const OnChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevState) => ({...prevState, [name]: value}));
    }

    const OnSubmit = async (event) => {
        event.preventDefault();
        setBooking(formData);
        history.push('/enquiry');
    }

    return (
        <Fragment>
            <h5>Book Fridge & Freezer Trailers</h5>
            <p>Book your Refrigerated or Freezer Trailer with Cool Hire today.</p>
            <p>Fill in the details below and we will contact you within 24 hours.</p>
            <Form onSubmit={OnSubmit}>
                <FormGroup>
                    <h6 className="required">Drop off date</h6>
                    <DatePicker
                        selected={startDate}
                        onChange={date => StartChange(date)}
                        selectsStart
                        minDate={minDate}
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        required
                    />
                    <FormText color="muted">We will confirm a drop off time with you closer to your reservation.</FormText>
                </FormGroup>
                <FormGroup>
                    <h6 className="required">Pick up date</h6>
                    <DatePicker
                        selected={endDate}
                        onChange={date => EndChange(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={minEnd.setDate(startDate.getDate() + 3)}
                        dateFormat="dd/MM/yyyy"
                        required
                    />
                    <FormText color="muted">We will confirm a pick up time with you closer to your reservation. Your booking must be a minimum of 3 days.</FormText>
                </FormGroup>
                <FormGroup>
                    <h6 className="required">Trailer Size Required</h6>
                    <Input type="select" name="size" id="size" defaultValue={'default'} onChange={OnChange} required>
                        <option value={'default'} disabled>-- Select One --</option>
                        <option>Wedding Trailer</option>
                        <option>Small Trailer</option>
                        <option>Medium Trailer</option>
                        <option>Large Trailer</option>
                    </Input>
                    <FormText color="muted">See the sizing grid for details on each.</FormText>
                </FormGroup>
                <FormGroup>
                    <h6 className="required">Number of Trailers required</h6>
                    <Input type="select" name="units" id="units" defaultValue={'default'} onChange={OnChange} required>
                        <option value={'default'} disabled>-- Select One --</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4+</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <h6 className="required">Temperature Range Required</h6>
                    <Input type="select" name="temperature" id="temperature" defaultValue={'default'} onChange={OnChange} required>
                        <option value={'default'} disabled>-- Select One --</option>
                        <option>Fresh Meat/Veg (0°c / +2°c)</option>
                        <option>General Purpose (+1°c / +4°c)</option>
                        <option>Chilled Drinks (+8°c / +10°c)</option>
                        <option>Frozen (-18°c / -20°c)</option>
                        <option>Custom Requirement</option>
                        <option>I don't know</option>
                    </Input>
                    <FormText color="muted">Above are the common use settings. We can configure the unit specific to your requirements, please detail these below.</FormText>
                </FormGroup>
                <FormGroup>
                    <h6 className="required">Basic Use Description</h6>
                    <Input type="textarea" name="use" id="units" style={{height: '100px'}} onChange={OnChange} required placeholder={'Input a basic description of your trailer need i.e. Wedding Food Storage'}/>
                </FormGroup>
                <Input type={'submit'} className={'btn btn-style-5'}><FontAwesomeIcon icon={faArrowRight} /> Continue</Input>
            </Form>
        </Fragment>

    )
}

export default BookTrailer