import Axios from "axios";

async function CheckfrontCreateBooking(sessionId, delivery, billing) {
    const URL = `${process.env.REACT_APP_AWS_API_URL}/bookings/new`;
    const config = {
        headers: {
            'X-API-Key': process.env.REACT_APP_AWS_API_KEY
        }
    }
    const data = {sessionId, delivery, billing}

    try {
        const response = await Axios.post(URL, data, config)
        return {id: response.data.booking.booking.id, token: response.data.booking.booking.token}
    }
    catch (err) {
        throw new Error(err)
    }
}

export default CheckfrontCreateBooking;