import React from 'react';
import {Row, Col} from 'reactstrap';

const ServiceBullets = (props) => {
    return (
        <Row>
            <Col lg={6} md={6} sm={12}>
                <ul className="custom-list type-1">
                    {
                        props.bullets.left.map(item => (
                            <li key={item}>{item}</li>
                        ))
                    }
                </ul>
            </Col>
            <Col lg={6} md={6} sm={12}>
                <ul className="custom-list type-1">
                    {
                        props.bullets.right.map(item => (
                            <li key={item}>{item}</li>
                        ))
                    }
                </ul>
            </Col>
        </Row>
    );
}

export default ServiceBullets;