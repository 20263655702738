import React, {useEffect, useState} from 'react';
import { UncontrolledCarousel } from 'reactstrap';
import IsLoading from "../../Global/IsLoading";

const ServiceCarousel = (props) => {
    const [isLoading, setLoading] = useState(true);
    const [items, setItems] = useState([]);

    useEffect(() => {
        setLoading(true);
        // we need to clean the state down, if we dont images between products which are blank cross contaminate
        setItems([])
        if (props.imageData !== undefined) {
            props.imageData.map((image) => {
                setItems(prevState => prevState.concat({
                    src: `${process.env.REACT_APP_AWS_S3_BUCKET}/ProductImages/${props.productId}/${image}`,
                    altText: props.productName,
                    caption: '',
                    key: image
                }))
                return null;
            })
        }
        setLoading(false);
    }, [props.imageData, props.productId, props.productName]);

    if (isLoading) {
        return <IsLoading />
    }

    if (props.imageData === undefined) {
        return null
    }

    return (
        <UncontrolledCarousel items={items} />
    )
}

export default ServiceCarousel;