import React from 'react';
import {useRecoilState} from "recoil";
import {Alert, Row, Col, Button, ButtonGroup} from 'reactstrap';
import {ViewedBooking} from "../../store/store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faExclamationCircle,
    faExclamationTriangle,
    faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import ErrorBoundary from "../Errors/ErrorBoundary";
import BookingItemsTable from "./ViewBookingDetails/BookingItemsTable";
import PrettyItemStatus from "./ViewBookingDetails/PrettyItemStatus";
import TransactionsTable from "./ViewBookingDetails/TransactionsTable";
import MakePaymentBtn from "./ViewBookingDetails/MakePaymentBtn";
import ViewInvoiceBtn from "./ViewBookingDetails/ViewInvoiceBtn";
import PrettyDate from "../Global/PrettyDate";

const ViewBookingDetail = () => {
    const [booking, setBooking] = useRecoilState(ViewedBooking)

    const resetBooking = () => {
        setBooking(undefined)
    }

    if (!booking) {
        return <Alert color={'danger'}><FontAwesomeIcon icon={faExclamationCircle}/> Oops, something sent wrong. Please refresh the page. </Alert>
    }

    const items = Object.values(booking.cf.items)
    const transactions = Object.values(booking.cf.transactions)

    return (
        <ErrorBoundary>
            <Row>
                <Col lg={'8'}>
                    <h2>Booking - {booking.cf.id}</h2>
                    <h5>Status: <PrettyItemStatus status={booking.cf.status_id} /></h5>
                </Col>
                <Col>
                    <ButtonGroup size={'sm'} style={{width: '100%'}}>
                        <Button size={'sm'} color={'secondary'} onClick={resetBooking}><FontAwesomeIcon icon={faArrowLeft} fixedWidth /> Log Out</Button>
                        <ViewInvoiceBtn bookingId={booking.cf.id} status={booking.cf.status_id} token={booking.cf.token} />
                        <MakePaymentBtn bookingId={booking.cf.id} status={booking.cf.status_id} token={booking.cf.token} />
                    </ButtonGroup>
                    <Alert color={'info'} style={{margin: '10px 0 0 0'}}><FontAwesomeIcon icon={faInfoCircle} fixedWidth /> If you need to update your booking please contact us via email <a href={'mailto:hello@coolhire.co'}>hello@coolhire.co</a>.</Alert>
                </Col>
            </Row>
            <hr />
            {
                booking.cf.status_id === 'PEND' &&
                    <Row>
                        <Col>
                            <Alert color={'warning'}>
                                <Row>
                                    <Col>
                                        <h5 className="alert-heading"><FontAwesomeIcon icon={faExclamationTriangle} /> Please make a deposit payment!</h5>
                                    </Col>
                                    <Col>
                                        <span style={{float: "right"}}><MakePaymentBtn bookingId={booking.cf.id} status={booking.cf.status_id} token={booking.cf.token} /></span>
                                    </Col>
                                </Row>
                                Your booking dates are not secured! Please use the button to make a payment and secure your booking.
                            </Alert>
                        </Col>
                    </Row>
            }
            {
                booking.cf.status_id === 'PART' &&
                <Row>
                    <Col>
                        <Alert color={'info'}>
                            <Row>
                                <Col>
                                    <h5 className="alert-heading"><FontAwesomeIcon icon={faExclamationCircle} /> Please make a final payment!</h5>
                                </Col>
                                <Col>
                                    <span style={{float: "right"}}><MakePaymentBtn bookingId={booking.cf.id} status={booking.cf.status_id} token={booking.cf.token} /></span>
                                </Col>
                            </Row>
                            Please make a final payment for your booking.
                        </Alert>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <h5>Billing Details</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h6>Contact Details</h6>
                    <p>
                        Name: {booking.cf.customer_name}<br />
                        Company: {booking.cf.meta.customer_company}<br />
                        Email: {booking.cf.customer_email}<br />
                        Phone: {booking.cf.customer_phone}
                    </p>
                </Col>
                <Col>
                    <h6>Billing Address</h6>
                    <p>
                        {booking.cf.customer_address}<br />
                        {booking.cf.customer_city}<br />
                        {booking.cf.customer_postal_zip}
                    </p>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <h5>Booking Information</h5>
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <h6>Booked Services</h6>
                    <BookingItemsTable items={items} />
                    <Row style={{textAlign: 'center'}}>
                        <Col>
                            <p><strong>Delivery Date:</strong> <PrettyDate date={items[0].start_date} /></p>
                        </Col>
                        <Col>
                            <p><strong>Collection Date:</strong> <PrettyDate date={items[0].end_date} /></p>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <h6>Delivery Contact</h6>
                    <p>
                        Name: {booking.cf.meta.delivery_name}<br />
                        Company: {booking.cf.meta.delivery_company}<br />
                        Phone: {booking.cf.meta.delivery_phone}
                    </p>
                    <h6>Delivery Address</h6>
                    <p>
                        {booking.cf.meta.delivery_address}<br />
                        {booking.cf.meta.delivery_city}<br />
                        {booking.cf.meta.delivery_postcode}<br />
                        Notes: {booking.cf.meta.delivery_notes}
                    </p>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <h5>Payment Information</h5>
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <h6>Booked Services</h6>
                    <TransactionsTable items={transactions} />
                </Col>
                <Col>
                    <h6>Payment Actions</h6>
                    <ViewInvoiceBtn bookingId={booking.cf.id} status={booking.cf.status_id} token={booking.cf.token} />
                    {' '}
                    <MakePaymentBtn bookingId={booking.cf.id} status={booking.cf.status_id} token={booking.cf.token} />
                </Col>
            </Row>
        </ErrorBoundary>
    )
}

export default ViewBookingDetail