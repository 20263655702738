import React, {Fragment} from 'react';
import {Row, Col} from 'reactstrap';
import ContactDetails from "../components/ContactPage/ContactDetails";
import ContactForm from "../components/ContactPage/ContactForm";
import {Helmet} from "react-helmet";

const ContactPage = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Cool Hire</title>
                <meta name="description" content={'Get in touch with the team at Cool Hire!'}/>
                <meta name="keywords" content={'contact,us,cool,hire,mobile,refrigeration'}/>
            </Helmet>
            <Row>
                <Col sm={12} md={4} lg={4}>
                    <ContactDetails />
                </Col>
                <Col>
                    <ContactForm />
                </Col>
            </Row>
        </Fragment>
    )
};

export default ContactPage