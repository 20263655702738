import React from 'react';
import {Col, Row, Spinner} from 'reactstrap';

const IsLoading = () => {
    return (
        <Row>
            <Col sm="12" md={{size: 6, offset: 3}} style={{textAlign:'center', paddingTop: '100px'}}>
                <Spinner style={{width: '3rem', height: '3rem'}} color="primary"/>
                <h3>Please Wait....</h3>
            </Col>
        </Row>
    )
}

export default IsLoading;