import {atom} from 'recoil';

// Page Data
export const PagesState = atom({
    key: 'pages',
    default: {
        set: false,
        pages: []
    }
})

// Services List
export const ServiceState = atom({
    key: 'services',
    default: []
})

// Gallery Page
export const GalleryState = atom({
    key: 'gallery',
    default: []
})

// for the homepage carousel
export const CarouselState = atom({
    key: 'carousel',
    default: []
})

// Used for the legacy enquiry booking form
export const CurrentBooking = atom({
    key: 'currentBooking',
    default: {}
})

// From View Your Booking
export const ViewedBooking = atom({
    key: 'viewedBooking',
    default: undefined
})