import React, {useEffect, useState, Fragment} from 'react';
import {Row, Col, Form, Alert, FormGroup, Label, Input, Button} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import NumberFormat from 'react-number-format';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBookmark,
    faCartPlus,
    faCheck,
    faMoneyBill,
    faPoundSign,
    faSpinner,
    faTag,
    faTruck,
    faTruckLoading,
    faUserClock
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {CurrentBooking} from "../store/store";
import IsLoading from "../components/Global/IsLoading";
import ErrorBoundary from "../components/Errors/ErrorBoundary";
import CheckfrontGetBookingSession from "../functions/Checkfront/CheckfrontGetBookingSession";
import counties from '../config/countiesList.json';
import PrettyDateFromString from "../components/Global/PrettyDateFromString";
import CheckfrontCreateBooking from "../functions/Checkfront/CheckfrontCreateBooking";
import BookingConfirmed from "./BookingConfirmed";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

const NewBooking = () => {
    const history = useHistory();
    const [message, setMessage] = useState({show: false});
    const [globalBooking, setGlobalBooking] = useRecoilState(CurrentBooking);
    const [booking, setBooking] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [disablePricing, setDisablePricing] = useState(false);
    const [disableBilling, setDisableBilling] = useState(false);
    const [bookingComplete, setBookingComplete] = useState({complete: false})

    useEffect(() => {
        setLoading(true)
        try {
            if (!globalBooking || !globalBooking.availability) {
                throw new Error('Booking error')
            }
            const startDate = Object.keys(globalBooking.availability.dates[0])
            const endDate = Object.keys(globalBooking.availability.dates[globalBooking.availability.dates.length - 1])
            setBooking({
                service: {startDate: startDate[0], endDate: endDate[0], item: globalBooking.item},
            });
            setGlobalBooking(undefined);
        }
        catch (err) {
            toast.warning('There was an issue loading this page. Please try again.')
            history.push('/');
        }
        setLoading(false)
        // eslint-disable-next-line
    }, [])

    const OnChange = (event) => {
        const {name, value} = event.target;
        setBooking((prevState) => ({...prevState, [name]: value}))
    }
    const DeliveryChange = (event) => {
        const {name, value} = event.target;
        setBooking((prevState) => ({...prevState, delivery: {...prevState.delivery, [name]: value}}))
    }
    const BillingChange = (event) => {
        const {name, value} = event.target;
        setBooking((prevState) => ({...prevState, billing: {...prevState.billing, [name]: value}}))
    }

    const SubmitBookingSession = async (event) => {
        event.preventDefault();
        try {
            setDisablePricing(true)
            const bookingSession = await CheckfrontGetBookingSession(booking.service.startDate, booking.service.endDate, booking.service.item.id, booking.delivery.delivery_county, booking.discountCode)
            setBooking((prevState) => ({...prevState, bookingSession}))
        } catch (err) {
            setDisablePricing(false)
            toast.warning('Sorry, we encountered an unexpected issue processing your request. Please try again or call us.')
        }
    }

    const SubmitBooking = async (event) => {
        event.preventDefault();
        try {
            setDisableBilling(true)
            const bookingSession = await CheckfrontCreateBooking(booking.bookingSession.id, booking.delivery, booking.billing);
            setBookingComplete({complete: true, id: bookingSession.id, token: bookingSession.token});
            setBooking({})
        } catch (err) {
            setDisableBilling(false)
            toast.warning('Sorry, we encountered an unexpected issue processing your request. Please try again or call us.')
        }
    }

    if (loading) {
        return <IsLoading/>
    }

    if (bookingComplete.complete) {
        return <BookingConfirmed id={bookingComplete.id} token={bookingComplete.token} />
    }

    return (
        <ErrorBoundary>
            <Row>
                <Col>
                    <h1><FontAwesomeIcon icon={faCartPlus}/> Get Pricing & Make Booking Request</h1>
                    <p>Thank you for choosing Cool Hire. Complete the form below to obtain an online price for your
                        selected service, if you wish to continue you may make a request for booking online to secure your selected dates.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Alert isOpen={message.show} color={message.color}
                           toggle={() => setMessage({show: false})}>{message.icon} {message.message}</Alert>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3><FontAwesomeIcon icon={faBookmark}/> Your Selected Service</h3>
                    <p>Here are the details of your service. Please check carefully, if they are incorrect please Go
                        Back and select the correct dates before proceeding.</p>
                </Col>
            </Row>
            <Row>
                {
                    booking.service.item.image[0] &&
                    <Col lg={4}>
                        <img src={booking.service.item.image[0].url} alt={booking.service.item.image[0].title}/>
                    </Col>
                }
                <Col>
                    <div className="table-type-2">
                        <table>
                            <tbody>
                            <tr>
                                <th style={{width: '30%'}}>Service Name:</th>
                                <td>{booking.service.item.name}</td>
                            </tr>
                            <tr>
                                <th>Service Summary:</th>
                                <td>{ReactHtmlParser(booking.service.item.summary)}</td>
                            </tr>
                            <tr>
                                <th>Delivery Date:</th>
                                <td><PrettyDateFromString date={booking.service.startDate}/></td>
                            </tr>
                            <tr>
                                <th>Collection Date:</th>
                                <td><PrettyDateFromString date={booking.service.endDate}/></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col>
                    <h3><FontAwesomeIcon icon={faTruckLoading}/> Pricing & Delivery</h3>
                    <p>To calculate a price please let us know details of the delivery.</p>
                    <Form onSubmit={SubmitBookingSession}>
                        <Row>
                            <Col>
                                <h5><FontAwesomeIcon icon={faUserClock}/> Delivery Contact</h5>
                                <p>This will be the main contact for the delivery.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="contactName">Contact Name</Label>
                                    <Input type="text" name="delivery_name" id="contactName" onChange={DeliveryChange}
                                           required disabled={disablePricing}/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="contactCompany">Contact Company</Label>
                                    <Input type="text" name="delivery_company" id="contactCompany"
                                           onChange={DeliveryChange} disabled={disablePricing}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="contactEmail">Contact Email</Label>
                                    <Input type="text" name="delivery_email" id="contactEmail" onChange={DeliveryChange}
                                           required disabled={disablePricing}/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="contactPhone">Contact Phone</Label>
                                    <Input type="text" name="delivery_phone" id="contactPhone" onChange={DeliveryChange}
                                           required disabled={disablePricing}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h5><FontAwesomeIcon icon={faTruck}/> Delivery Address</h5>
                                <p>This will be the address we will deliver the service to.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="deliveryAddress">Delivery Address</Label>
                                    <Input type="text" name="delivery_address" id="deliveryAddress"
                                           onChange={DeliveryChange} required disabled={disablePricing}/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="deliveryCity">Delivery City</Label>
                                    <Input type="text" name="delivery_city" id="deliveryCity" onChange={DeliveryChange}
                                           required disabled={disablePricing}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="deliveryCounty">Delivery County</Label>
                                    <Input type="select" name="delivery_county" id="deliveryCounty"
                                           defaultValue={'default'} onChange={DeliveryChange} required
                                           disabled={disablePricing}>
                                        <option value={'default'} disabled>-- Select One --</option>
                                        {
                                            counties.map((county) => {
                                                return <option key={county.value}
                                                               value={county.value}>{county.name}</option>
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="deliveryPostCode">Delivery Postcode</Label>
                                    <Input type="text" name="delivery_postcode" id="deliveryPostCode"
                                           onChange={DeliveryChange} required/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h5><FontAwesomeIcon icon={faTag}/> Service/Discount Code</h5>
                                <p>If you have been given a service/discount code please enter it in here (otherwise leave this
                                    blank, we will apply any open discounts automatically!).</p>
                                <Input type="text" name="discountCode" id="discountCode" onChange={OnChange}
                                       disabled={disablePricing}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {
                                    !booking.bookingSession ?
                                        disablePricing ? <Button type={'submit'} block disabled><FontAwesomeIcon icon={faSpinner} spin /> Loading....</Button>
                                        :
                                        <Button type={'submit'} color={'primary'} block><FontAwesomeIcon icon={faCheck} /> Get Pricing!</Button>
                                        : null
                                }
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            {
                booking.bookingSession &&
                <Fragment>
                    <Row>
                        <Col>
                            <h3><FontAwesomeIcon icon={faPoundSign} fixedWidth/> Your Pricing</h3>
                            <p>Below is the price for your selected dates, service which includes delivery and VAT.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="table-type-1">
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>Item</th>
                                        <th>Summary</th>
                                        <th>Value</th>
                                    </tr>
                                    {
                                        <tr>
                                            <td>{booking.bookingSession.item['1'].name}</td>
                                            <td><strong>Dates:</strong> {booking.bookingSession.item['1'].date.summary}
                                            </td>
                                            <td><NumberFormat value={booking.bookingSession.item['1'].rate.total}
                                                              displayType={'text'} decimalSeparator={'.'} prefix={'£'}
                                                              decimalScale={2} fixedDecimalScale={true}/></td>
                                        </tr>
                                    }
                                    {
                                        <tr>
                                            <td>{booking.bookingSession.item['2'].name}</td>
                                            <td>One time delivery & collection fee to <span
                                                style={{textTransform: "capitalize"}}>{booking.delivery.delivery_county}</span> address.
                                            </td>
                                            <td><NumberFormat value={booking.bookingSession.item['2'].rate.total}
                                                              displayType={'text'} decimalSeparator={'.'} prefix={'£'}
                                                              decimalScale={2} fixedDecimalScale={true}/></td>
                                        </tr>
                                    }
                                    {
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{textAlign: 'right'}}>Sub Total (including discounts):</td>
                                            <td><NumberFormat value={booking.bookingSession.sub_total}
                                                              displayType={'text'} decimalSeparator={'.'} prefix={'£'}
                                                              decimalScale={2} fixedDecimalScale={true}/></td>
                                        </tr>
                                    }
                                    {
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{textAlign: 'right'}}>UK VAT
                                                @ {booking.bookingSession.tax['1'].amount}%:
                                            </td>
                                            <td><NumberFormat value={booking.bookingSession.tax_total}
                                                              displayType={'text'} decimalSeparator={'.'} prefix={'£'}
                                                              decimalScale={2} fixedDecimalScale={true}/></td>
                                        </tr>
                                    }
                                    {
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td style={{textAlign: 'right'}}>Total Price:</td>
                                            <td><NumberFormat value={booking.bookingSession.total} displayType={'text'}
                                                              decimalSeparator={'.'} prefix={'£'} decimalScale={2}
                                                              fixedDecimalScale={true}/></td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5><FontAwesomeIcon icon={faMoneyBill}/> Billing Details</h5>
                            <p>The following details are used for payment and invoicing.</p>
                        </Col>
                    </Row>
                    <Form onSubmit={SubmitBooking}>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="customer_name">Billing Name</Label>
                                    <Input type="text" name="customer_name" id="customer_name" onChange={BillingChange}
                                           required disabled={disableBilling}/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="customer_company">Billing Company</Label>
                                    <Input type="text" name="customer_company" id="customer_company"
                                           onChange={BillingChange} disabled={disableBilling}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="customer_email">Billing Email</Label>
                                    <Input type="text" name="customer_email" id="customer_email" onChange={BillingChange}
                                           required disabled={disableBilling}/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="customer_phone">Billing Phone</Label>
                                    <Input type="text" name="customer_phone" id="customer_phone" onChange={BillingChange}
                                           required disabled={disableBilling}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="customer_address">Billing Address</Label>
                                    <Input type="text" name="customer_address" id="customer_address"
                                           onChange={BillingChange} required disabled={disableBilling}/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="customer_city">Billing City</Label>
                                    <Input type="text" name="customer_city" id="customer_city" onChange={BillingChange}
                                           required disabled={disableBilling}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="customer_region">Billing County</Label>
                                    <Input type="select" name="customer_region" id="customer_region"
                                           defaultValue={'default'} onChange={BillingChange} required disabled={disableBilling}>
                                        <option value={'default'} disabled>-- Select One --</option>
                                        {
                                            counties.map((county) => {
                                                return <option key={county.value}
                                                               value={county.value}>{county.name}</option>
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="customer_postal_zip">Billing Postcode</Label>
                                    <Input type="text" name="customer_postal_zip" id="customer_postal_zip"
                                           onChange={BillingChange} required disabled={disableBilling}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup check>
                                    By making a booking request:
                                    <ul style={{listStyle: 'inside square'}}>
                                        <li>
                                            I agree to <Link to={'/info/terms'} target="_blank">Terms & Conditions</Link> outlined by Cool Hire
                                        </li>
                                        <li>
                                            I agree to the <Link to={'/info/privacy'} target="_blank">Privacy Policy</Link> provided by Cool Hire
                                        </li>
                                        <li>
                                            Delivery of the selected service can be made to an accessible location by motor vehicle.
                                        </li>
                                    </ul>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Col>
                            {
                                disableBilling ? <Button color={'primary'} block disabled><FontAwesomeIcon icon={faSpinner} spin /> Loading...</Button>
                                    :
                                    <Button type={'submit'} color={'primary'} block><FontAwesomeIcon icon={faCheck} /> Make Booking Request</Button>
                            }
                        </Col>
                    </Form>

                </Fragment>
            }
        </ErrorBoundary>
    )
}

export default NewBooking