import Axios from "axios";

async function GetSiteData() {
    try {
        const URL = `${process.env.REACT_APP_AWS_API_URL}/getsitedata`;
        const config = {
            headers: {
                'X-API-Key': process.env.REACT_APP_AWS_API_KEY
            }
        }
        const response = await Axios.get(URL, config);
        if (response.data.success === false) {
            throw new Error('The request for data failed at the server.')
        }
        return response.data.data.Items
    }
    catch (error) {
        throw new Error(error)
    }
}

export default GetSiteData;