import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {ServiceState} from "../../store/store";

const MobileMenuItems = (props) => {
    const services = useRecoilValue(ServiceState);
    return (
        <Fragment>
            <ul style={{textAlign: 'left', fontSize: '24px'}}>
                <li><Link to={'/'} onClick={props.toggle}>Home</Link></li>
                <li>Fridge & Freezer Trailers
                    <ul>
                        {services.map(service => {
                            if (service.serviceType === 'trailer') {
                                return <li key={service.id} style={{marginLeft: '20px'}}><Link
                                    to={`/services/${service.id}`} onClick={props.toggle}>{service.friendlyName}</Link>
                                </li>
                            }
                            return null
                        })}
                    </ul>
                </li>
                <li>Cooling & Heating
                    <ul>
                        {services.map(service => {
                            if (service.serviceType === 'portables') {
                                return <li key={service.id} style={{marginLeft: '20px'}}><Link
                                    to={`/services/${service.id}`} onClick={props.toggle}>{service.friendlyName}</Link>
                                </li>
                            }
                            return null
                        })}
                    </ul>
                </li>
                <li><Link to={'/gallery'} onClick={props.toggle}>Gallery</Link></li>
                <li><Link to={"/contact-us"} onClick={props.toggle}>Contact Us</Link></li>
            </ul>
        </Fragment>
    )
};

export default MobileMenuItems