import React from 'react';
import {Link} from 'react-router-dom';
import MenuItems from "../../components/Global/MenuItems";
import logo from "../../images/logo.png";
import MobileMenu from "./MobileMenu";

const MainMenu = () => {
    return (
        <div className="menu-holder">
            <div className="menu-wrap">
                <div className="container extra-width-2">
                    <div className="flex-row flex-center flex-justify">
                        <div className="logo-wrap">
                            <Link to={'/'}><img src={logo} alt="Cool Hire Mobile Refrigeration" style={{width: '170px'}} /></Link>
                        </div>
                        <div className="nav-item flex-row flex-center">
                            <nav id="main-navigation" className="main-navigation">
                                <ul id="menu" className="clearfix">
                                    <MenuItems />
                                </ul>
                            </nav>
                            <MobileMenu id="responsive-nav-button" className="responsive-nav-button" toggle={false} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainMenu